<template>
  <div class="w-full text-white">
    <div class="w-full flex flex-wrap justify-center mt-10" style="white-space: pre-wrap" :style="[{
      paddingLeft: fixScale(107, 0),
      paddingRight: fixScale(71, 0)
    }]">
      <div class="w-5/12">
        <div class="font-bold mx-1 md:mx-3" :style="[{
          fontSize: fixScale(48, 16)
        }]">{{$t('WOR PRICE')}}</div>
        <div :style="[{
          fontSize: fixScale(50, 16)
        }]">{{fixPrice(worInfo.price, 10, -1)}}</div>
      </div>
      <div  class="w-5/12">
        <div class="font-bold mx-1 md:mx-3" style="white-space: pre-wrap" :style="[{
          fontSize: fixScale(48, 16)
        }]">{{$t('Total Current\n Supply')}}</div>
        <div :style="[{
          fontSize: fixScale(50, 16)
        }]">{{ fixPrice(worInfo.totalActive, 8, 0) }}</div>
      </div>
      <div class="w-5/12 mt-3">
        <div class="font-bold mx-1 md:mx-3" style="min-width: 100px;" :style="[{
          fontSize: fixScale(48, 16)
        }]">{{$t('Total Supply')}}</div>
        <div :style="[{
          fontSize: fixScale(50, 16)
        }]">{{fixPrice(worInfo.totalSupply, 8, 0)}}</div>
      </div>
      <div class="w-5/12 mt-3">
        <div class="font-bold mx-1 md:mx-3" style="min-width: 100px;" :style="[{
          fontSize: fixScale(48, 16)
        }]">{{$t('Market Cap')}}</div>
        <div :style="[{
          fontSize: fixScale(50, 16)
        }]">$ {{marketCap}}</div>
      </div>
      <div class="w-5/12 mt-3">
        <div class="font-bold mx-1 md:mx-3" style="min-width: 100px;" :style="[{
          fontSize: fixScale(48, 16)
        }]">{{$t('Total Burn')}}</div>
        <div :style="[{
          fontSize: fixScale(50, 16)
        }]">{{fixPrice(worInfo.totalBurn, 8, 0)}}</div>
      </div>
      <div class="w-5/12 mt-3">
        <div class="font-bold mx-1 md:mx-3" style="min-width: 100px;" :style="[{
          fontSize: fixScale(48, 16)
        }]">{{$t('Burn Cap')}}</div>
        <div :style="[{
          fontSize: fixScale(50, 16)
        }]">$ {{ burnCap }}</div>
      </div>
      <div class="w-5/12 mt-3">
        <div class="font-bold mx-1 md:mx-3" style="min-width: 100px;" :style="[{
          fontSize: fixScale(48, 16)
        }]">{{$t('Burn Rate')}}</div>
        <div :style="[{
          fontSize: fixScale(50, 16)
        }]">{{ burnRate }}%</div>
      </div>
      <div class="w-5/12 mt-3">
        <div class="font-bold mx-1 md:mx-3" style="min-width: 100px;" :style="[{
          fontSize: fixScale(48, 16)
        }]">{{$t('Better Hold')}}</div>
        <div :style="[{
          fontSize: fixScale(50, 16)
        }]">{{ fixPrice(worInfo.betterHoldNum, 8, 2) }}</div>
      </div>
      <div class="w-5/12 mt-3">
        <div class="font-bold mx-1 md:mx-3" style="min-width: 100px;" :style="[{
          fontSize: fixScale(48, 16)
        }]">{{$t('Holders')}}</div>
        <div :style="[{
          fontSize: fixScale(50, 16)
        }]">{{worInfo.shareholders}}</div>
      </div>
      <div class="w-5/12 mt-3">
        <div class="font-bold mx-1 md:mx-3" style="min-width: 100px; white-space: pre-wrap" :style="[{
          fontSize: fixScale(48, 16)
        }]">{{$t('Total USDT \n dividend')}}</div>
        <div :style="[{
          fontSize: fixScale(50, 16)
        }]">{{fixPrice(worInfo.totalUSDT, 18, 0)}}</div>
      </div>
      <div class="w-5/12 mt-3">
        <div class="font-bold mx-1 md:mx-3" style="min-width: 100px; white-space: pre-wrap" :style="[{
          fontSize: fixScale(48, 16)
        }]">{{$t('Pancake Liquidity')}}</div>
        <div :style="[{
          fontSize: fixScale(50, 16)
        }]">{{liquidityRate}}%</div>
      </div>
      <div class="w-5/12 mt-3 invisible">
        <div class="font-bold mx-1 md:mx-3" style="min-width: 100px; white-space: pre-wrap" :style="[{
          fontSize: fixScale(48, 16)
        }]">{{$t('Pancake Liquidity')}}</div>
        <div :style="[{
          fontSize: fixScale(50, 16)
        }]">{{liquidityRate}}%</div>
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import {getContract, isAddress} from "@/utils/contract";
import {log} from "@/utils/tools";
import WORAbi from "@/assets/abi/WORAbi.json";

export default {
  name: "Info",
  computed: {
    fixScale() {
      let self = this;
      return (value, min) => {
        let _value = value*self.$store.state.scale;
        if (_value < min) {
          return min+"px";
        }
        return _value+"px";
      }
    },
    wallet() {
      return this.$store.state.wallet;
    },
    fixPrice() {
      return (p, n, d) => {
        if (d == -1) {
          return new BigNumber(p).div((10**n)+"").toNumber()
        }
        return new BigNumber(p).div((10**n)+"").toFixed(d)
      }
    },
    marketCap() {
      if (new BigNumber(this.worInfo.price).lte("0")) {
        return 0;
      }
      return new BigNumber(this.worInfo.price).times(this.worInfo.totalSupply).div((10**10)+"").div((10**8)+"").toFixed(0);
    },
    burnCap() {
      if (new BigNumber(this.worInfo.price).lte("0")) {
        return 0;
      }
      return new BigNumber(this.worInfo.price).times(this.worInfo.totalBurn).div((10**10)+"").div((10**8)+"").toFixed(0);
    },
    burnRate() {
      if (new BigNumber(this.worInfo.totalBurn).lte("0")) {
        return 0;
      }
      return Math.floor(new BigNumber(this.worInfo.totalBurn).times("100").div(this.worInfo.totalSupply).times("1000000").toNumber())/1000000;
    },
    liquidityRate() {
      if (new BigNumber(this.worInfo.totalPairW).lte("0")) {
        return 0;
      }
      return Math.floor(new BigNumber(this.worInfo.totalPairW).times("100").div(this.worInfo.totalSupply).times("1000000").toNumber())/1000000;
    },
  },
  mounted() {
    let self = this;
    // window.onresize = () => {
    //   return (() => {
    //     const width = document.body.clientWidth
    //     console.log(`width = ${width}`)
    //     self.scale = width < 1920 ? width/1920 : 1;
    //   })()
    // }
    // const width = document.body.clientWidth
    // self.scale = width < 1920 ? width/1920 : 1;
    //
    // setTimeout((() => {
    //   const width = document.body.clientWidth
    //   self.scale = width < 1920 ? width/1920 : 1;
    // }, 500))

    setInterval(()=> {
      self.loadValues();
    }, 6000);

    setTimeout((() => {
      self.loadValues();
    }, 1000))
  },
  data() {
    return {
      worInfo: {
        price: 0,
        betterHoldNum: 0,
        totalSupply: 0,
        totalActive: 0,
        shareholders: 0,
        totalBurn: 0,
        totalPairW: 0,
        totalPairU: 0,
        totalUSDT: 0
      },
    }
  },
  methods: {
    async loadValues() {
      if (!this.wallet || !isAddress(this.wallet)) {
        log(this.wallet)
        setTimeout((() => {
          this.loadValues();
        }, 1000))
        return
      }
      log(`this.wallet = ${this.wallet}`);
      const wor = process.env.VUE_APP_WOR;
      const worC = getContract(wor, WORAbi);
      let _worInfo = await worC.methods.worInfo().call();
      this.worInfo = _worInfo;
    }
  }
}
</script>

<style scoped>

</style>