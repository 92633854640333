var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full text-white"},[_c('div',{staticClass:"w-full flex flex-wrap justify-center mt-10",staticStyle:{"white-space":"pre-wrap"},style:([{
    paddingLeft: _vm.fixScale(107, 0),
    paddingRight: _vm.fixScale(71, 0)
  }])},[_c('div',{staticClass:"w-5/12"},[_c('div',{staticClass:"font-bold mx-1 md:mx-3",style:([{
        fontSize: _vm.fixScale(48, 16)
      }])},[_vm._v(_vm._s(_vm.$t('WOR PRICE')))]),_c('div',{style:([{
        fontSize: _vm.fixScale(50, 16)
      }])},[_vm._v(_vm._s(_vm.fixPrice(_vm.worInfo.price, 10, -1)))])]),_c('div',{staticClass:"w-5/12"},[_c('div',{staticClass:"font-bold mx-1 md:mx-3",staticStyle:{"white-space":"pre-wrap"},style:([{
        fontSize: _vm.fixScale(48, 16)
      }])},[_vm._v(_vm._s(_vm.$t('Total Current\n Supply')))]),_c('div',{style:([{
        fontSize: _vm.fixScale(50, 16)
      }])},[_vm._v(_vm._s(_vm.fixPrice(_vm.worInfo.totalActive, 8, 0)))])]),_c('div',{staticClass:"w-5/12 mt-3"},[_c('div',{staticClass:"font-bold mx-1 md:mx-3",staticStyle:{"min-width":"100px"},style:([{
        fontSize: _vm.fixScale(48, 16)
      }])},[_vm._v(_vm._s(_vm.$t('Total Supply')))]),_c('div',{style:([{
        fontSize: _vm.fixScale(50, 16)
      }])},[_vm._v(_vm._s(_vm.fixPrice(_vm.worInfo.totalSupply, 8, 0)))])]),_c('div',{staticClass:"w-5/12 mt-3"},[_c('div',{staticClass:"font-bold mx-1 md:mx-3",staticStyle:{"min-width":"100px"},style:([{
        fontSize: _vm.fixScale(48, 16)
      }])},[_vm._v(_vm._s(_vm.$t('Market Cap')))]),_c('div',{style:([{
        fontSize: _vm.fixScale(50, 16)
      }])},[_vm._v("$ "+_vm._s(_vm.marketCap))])]),_c('div',{staticClass:"w-5/12 mt-3"},[_c('div',{staticClass:"font-bold mx-1 md:mx-3",staticStyle:{"min-width":"100px"},style:([{
        fontSize: _vm.fixScale(48, 16)
      }])},[_vm._v(_vm._s(_vm.$t('Total Burn')))]),_c('div',{style:([{
        fontSize: _vm.fixScale(50, 16)
      }])},[_vm._v(_vm._s(_vm.fixPrice(_vm.worInfo.totalBurn, 8, 0)))])]),_c('div',{staticClass:"w-5/12 mt-3"},[_c('div',{staticClass:"font-bold mx-1 md:mx-3",staticStyle:{"min-width":"100px"},style:([{
        fontSize: _vm.fixScale(48, 16)
      }])},[_vm._v(_vm._s(_vm.$t('Burn Cap')))]),_c('div',{style:([{
        fontSize: _vm.fixScale(50, 16)
      }])},[_vm._v("$ "+_vm._s(_vm.burnCap))])]),_c('div',{staticClass:"w-5/12 mt-3"},[_c('div',{staticClass:"font-bold mx-1 md:mx-3",staticStyle:{"min-width":"100px"},style:([{
        fontSize: _vm.fixScale(48, 16)
      }])},[_vm._v(_vm._s(_vm.$t('Burn Rate')))]),_c('div',{style:([{
        fontSize: _vm.fixScale(50, 16)
      }])},[_vm._v(_vm._s(_vm.burnRate)+"%")])]),_c('div',{staticClass:"w-5/12 mt-3"},[_c('div',{staticClass:"font-bold mx-1 md:mx-3",staticStyle:{"min-width":"100px"},style:([{
        fontSize: _vm.fixScale(48, 16)
      }])},[_vm._v(_vm._s(_vm.$t('Better Hold')))]),_c('div',{style:([{
        fontSize: _vm.fixScale(50, 16)
      }])},[_vm._v(_vm._s(_vm.fixPrice(_vm.worInfo.betterHoldNum, 8, 2)))])]),_c('div',{staticClass:"w-5/12 mt-3"},[_c('div',{staticClass:"font-bold mx-1 md:mx-3",staticStyle:{"min-width":"100px"},style:([{
        fontSize: _vm.fixScale(48, 16)
      }])},[_vm._v(_vm._s(_vm.$t('Holders')))]),_c('div',{style:([{
        fontSize: _vm.fixScale(50, 16)
      }])},[_vm._v(_vm._s(_vm.worInfo.shareholders))])]),_c('div',{staticClass:"w-5/12 mt-3"},[_c('div',{staticClass:"font-bold mx-1 md:mx-3",staticStyle:{"min-width":"100px","white-space":"pre-wrap"},style:([{
        fontSize: _vm.fixScale(48, 16)
      }])},[_vm._v(_vm._s(_vm.$t('Total USDT \n dividend')))]),_c('div',{style:([{
        fontSize: _vm.fixScale(50, 16)
      }])},[_vm._v(_vm._s(_vm.fixPrice(_vm.worInfo.totalUSDT, 18, 0)))])]),_c('div',{staticClass:"w-5/12 mt-3"},[_c('div',{staticClass:"font-bold mx-1 md:mx-3",staticStyle:{"min-width":"100px","white-space":"pre-wrap"},style:([{
        fontSize: _vm.fixScale(48, 16)
      }])},[_vm._v(_vm._s(_vm.$t('Pancake Liquidity')))]),_c('div',{style:([{
        fontSize: _vm.fixScale(50, 16)
      }])},[_vm._v(_vm._s(_vm.liquidityRate)+"%")])]),_c('div',{staticClass:"w-5/12 mt-3 invisible"},[_c('div',{staticClass:"font-bold mx-1 md:mx-3",staticStyle:{"min-width":"100px","white-space":"pre-wrap"},style:([{
        fontSize: _vm.fixScale(48, 16)
      }])},[_vm._v(_vm._s(_vm.$t('Pancake Liquidity')))]),_c('div',{style:([{
        fontSize: _vm.fixScale(50, 16)
      }])},[_vm._v(_vm._s(_vm.liquidityRate)+"%")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }